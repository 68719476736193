import React from "react"
import { Link } from "gatsby"

import DocumentHead from "../components/DocumentHead"
import Layout from "../components/Layout"
import pageNotFoundImage from "../images/page-not-found.gif"

export const Head = () => <DocumentHead pageTitle="Page Not Found" />

const NotFoundPage = () => (
  <Layout suppressHeader>
    <h1>Page Not Found</h1>
    <img src={pageNotFoundImage} alt="Page not found" />
    <p>Looks like something got moved.</p>
    <Link to="/">Home.</Link>
  </Layout>
)

export default NotFoundPage
