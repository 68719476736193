import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

export function DocumentHead(props) {
  const { pageTitle } = props
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  return (
    <>
      <title>{pageTitle}</title>
      <meta name="description" content={site.siteMetadata.description} />
      <meta name="og:title" content={pageTitle} />
      <meta name="og:description" content={site.siteMetadata.description} />
      <meta name="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:title" content={pageTitle} />
      <meta
        name="twitter:description"
        content={site.siteMetadata.description}
      />
    </>
  )
}

DocumentHead.propTypes = {
  pageTitle: PropTypes.string.isRequired,
}

export default DocumentHead
