import React from "react"
import PropTypes from "prop-types"

const Header = ({ title, subtitle }) => (
  <header>
    <div>
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
    </div>
  </header>
)

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

Header.defaultProps = {
  title: ``,
  subtitle: ``,
}

export default Header
